<template>
  <div class="page">
    <div class="title-container">
      <img
        src="https://img.miaocang.cc/pcImg/cloud/flower-tour/guide/title_gonglue@2x.png"
        class="title-image"
        oncontextmenu="return false;"
      />
    </div>
    <div class="brief-container">
      <img
        src="https://img.miaocang.cc/pcImg/cloud/flower-tour/guide/box_gonglue@2x.png"
        class="brief-image"
        oncontextmenu="return false;"
      />
      <div class="btn-wrap">
        <img
          src="https://img.miaocang.cc/pcImg/cloud/flower-tour/guide/box_jingdianjieshao@2x.png"
          class="btn"
          oncontextmenu="return false;"
          @click="toFlowerTourIntroductionPage"
        />
        <img
          src="https://img.miaocang.cc/pcImg/cloud/flower-tour/guide/box_luxian@2x.png"
          class="btn"
          oncontextmenu="return false;"
          @click="toFlowerTourRoutePage"
        />
      </div>
    </div>
    <div class="guide-container">
      <div class="title-wrap">
        <div class="title">游览小贴士</div>
        <img
          src="https://img.miaocang.cc/pcImg/cloud/flower-tour/guide/icon_xiahuaxian@2x.png"
          class="underline-image"
          oncontextmenu="return false;"
        />
      </div>
      <div class="list-wrap">
        <div
          v-for="{ title, content } in guideList"
          :key="title"
          class="list-item"
        >
          <div class="item-title">{{ title }}</div>
          <div class="item-content">{{ content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlowerTourGuide",

  data() {
    return {
      guideList: [
        {
          title: "一、关于交通",
          content: `为确保游客乘坐车体验更好，避开市集、限高及拥堵路段，建议游客均从沙古公路往西二环进入古神公路，再进入横栏辖内各游览点。`,
        },
        {
          title: "二、关于停/乘车",
          content: `西江花洲公园六沙段入口和三沙段入口均设有停车区，游客可按照指引停车，入园后选择步行或乘坐观光车进行观光体验（单次车费8元/人）。`,
        },
        {
          title: "三、关于便民服务点",
          content: `西江花洲公园2号驿站周边开放10个便民服务点，提供本土特色美食、点心和茶饮，以及本土自产自销的菜干、葛干、竹芋粉、鱼干、番薯干等。`,
        },
        {
          title: "四、游览时间建议",
          content: `西江花洲公园早上空气清新怡人，徒步的游客建议首选晨早时段；西江日落景象如诗如画，如时间允许，建议游客选择傍晚时分前往，欣赏晚霞与江景的无缝融合，感受大美横栏的浪漫和热情。`,
        },
      ],
    };
  },

  created() {
    document.title = '"探花游" 文旅攻略'
  },

  methods: {
    /* 跳转到 "探花游" 景点介绍页 */
    toFlowerTourIntroductionPage() {
      this.$router.push({
        name: "FlowerTourIntroduction",
      });
    },

    /* 跳转到 "探花游" 路线图页 */
    toFlowerTourRoutePage() {
      this.$router.push({
        name: "FlowerTourRoute",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  min-height: 100vh;
  max-width: 840px;
  margin: 0 auto;
  padding-bottom: 28px;
  text-align: center;
  background-color: #f3d7b3;
  .title-container {
    padding: 28px 0;
    .title-image {
      width: 73%;
    }
  }
  .brief-container {
    position: relative;
    text-align: center;
    width: 93%;
    margin: 0 auto 52px;
    .brief-image {
      width: 100%;
      z-index: 2;
    }
    .btn-wrap {
      position: absolute;
      bottom: 44px;
      left: 12px;
      display: flex;
      justify-content: space-around;
      width: 100%;
      z-index: 3;
      .btn {
        width: 38%;
        pointer-events: auto;
      }
    }
  }
  .guide-container {
    .title-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      margin-bottom: 28px;
      font-size: 36px;
      font-weight: 400;
      color: #6e3c0f;
      line-height: 62px;
      .underline-image {
        width: 33%;
      }
    }
    .list-wrap {
      padding: 0 32px;
      text-align: left;
      color: #673f18;
      .list-item {
        padding-bottom: 28px;
        .item-title {
          font-size: 32px;
          margin-bottom: 8px;
        }
        .item-content {
          font-size: 24px;
          line-height: 36px;
        }
      }
    }
  }
  img {
    user-select: none;
    pointer-events:none;
  }
}
</style>
